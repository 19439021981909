import React, { useEffect } from 'react'
import Header from '../components/Header'
import SingleTicket from '../components/SingleTicket'
import { useNavigate } from 'react-router-dom'


const SingleTicketPage = (props) => {
    const navigate = useNavigate()
    const { userInfo, setUserInfo, ticketList, setTicketList } = props
    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        }
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Header userInfo={userInfo} setUserInfo={setUserInfo} />
            <SingleTicket ticketList={ticketList}
                setTicketList={setTicketList} />

        </>
    )
}

export default SingleTicketPage