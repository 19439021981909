import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Signup = (props) => {
    const navigate = useNavigate();
    const [loginState, setLoginState] = useState(true);
    const { signUp, setSignUp, info, setInfo, loginForm, setLoginForm } = props;
    const { username, fullName, matric, password, confirm_password } = signUp;
    const handleChange = (event) => {
        const { name, value } = event.target;
        setSignUp({ ...signUp, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoginState(false);
        //const url = "http://localhost:5000/signup"
        const url = "https://it-support-ticket.onrender.com/signup";
        try {
            await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(signUp),
                //credentials: true
            })
                .then((response) => response.json())
                .then((data) => {
                    setInfo({
                        ...info,
                        signUpInfo: data.error
                            ? data
                            : { message: data.message },
                    });
                    setLoginState(true);
                    if (data.message) {
                        setLoginForm({ ...loginForm, username: data.user });
                        navigate("/login");
                    }
                });
        } catch (err) {
            setInfo({ ...info, signUpInfo: err.message });
            window.alert("Error from the server, try again later");
            setLoginState(true);
        }
    };
    return (
        <div className="flex  justify-center  gap-4 w-full px-2 py-2 ">
            <div className="flex flex-col lg:flex-row lg:w-9/12 w-11/12  justify-between ">
                <form
                    className="lg:w-8/12 w-full flex flex-col justify-center px-4 border border-slate-300 rounded lg:p-12 p-4 bg-white"
                    onSubmit={handleSubmit}
                >
                    <h1 className="font-bold text-center">
                        Sign up for your FCAHPT Support Center account
                    </h1>
                    <div className="flex lg:flex-row flex-col items-center gap-2  w-full my-1">
                        <label
                            className="text-sm lg:mr-8 lg:w-3/12 w-full font-normal"
                            htmlFor="username"
                        >
                            Email
                        </label>
                        <input
                            type="email"
                            name="username"
                            id="username"
                            className="inline w-full border rounded outline-0 focus:outline-0 active:outline-0 px-4 py-1 lg:my-2 my-0 bg-white border-slate-300"
                            onChange={handleChange}
                            value={username}
                            required
                        />
                    </div>
                    <div className="flex lg:flex-row flex-col items-center gap-2  w-full my-1">
                        <label
                            className="text-sm lg:mr-8 lg:w-3/12 w-full font-normal"
                            htmlFor="fullName"
                        >
                            Full name
                        </label>
                        <input
                            type="text"
                            name="fullName"
                            id="fullName"
                            className="inline w-full border rounded outline-0 focus:outline-0 active:outline-0 px-4 py-1 lg:my-2 my-0 bg-white border-slate-300"
                            onChange={handleChange}
                            value={fullName}
                            required
                        />
                    </div>
                    <div className="flex lg:flex-row flex-col items-center gap-2  w-full my-1">
                        <label
                            className="text-sm lg:mr-8 lg:w-3/12 w-full font-normal"
                            htmlFor="fullName"
                        >
                            Matric Number
                        </label>
                        <input
                            type="text"
                            name="matric"
                            id="matric"
                            className="inline w-full border rounded outline-0 focus:outline-0 active:outline-0 px-4 py-1 lg:my-2 my-0 bg-white border-slate-300"
                            onChange={handleChange}
                            value={matric}
                            required
                        />
                    </div>
                    <div className="flex lg:flex-row flex-col items-start gap-2  w-full my-1">
                        <label
                            className="text-sm lg:mr-8 lg:w-3/12 w-full font-normal"
                            htmlFor="password"
                        >
                            Password
                        </label>
                        <input
                            type="password"
                            name="password"
                            id="password"
                            className="resize-none inline text-sm w-full border rounded outline-0 focus:outline-0 active:outline-0 px-4 py-1 lg:my-2 my-0 bg-white border-slate-300"
                            onChange={handleChange}
                            value={password}
                            required
                        />
                    </div>
                    <div className="flex lg:flex-row flex-col items-start gap-2  w-full my-1">
                        <label
                            className="text-sm lg:mr-8 lg:w-3/12 w-full font-normal"
                            htmlFor="confirm_password"
                        >
                            Confirm Password
                        </label>
                        <input
                            type="password"
                            name="confirm_password"
                            id="confirm_password"
                            className="resize-none inline text-sm w-full border rounded outline-0 focus:outline-0 active:outline-0 px-4 py-1 lg:my-2 my-0 bg-white border-slate-300"
                            onChange={handleChange}
                            value={confirm_password}
                            required
                        />
                    </div>

                    <button type="submit" className="button_1">
                        {loginState ? (
                            "Signup"
                        ) : (
                            <span className="w-full flex items-center justify-center">
                                <div className="loading"></div>
                            </span>
                        )}
                    </button>
                    <a href="/login">
                        <em className=" text-center w-full text-sm">
                            Already have an account? Login.
                        </em>
                    </a>
                    {info.signUpInfo.message ? (
                        <em className="text-center px-2 py-1 bg-green-100 rounded mt-2">
                            {info.signUpInfo.message}
                        </em>
                    ) : null}
                    {info.signUpInfo.error ? (
                        <em className="text-center px-2 py-1 bg-red-100 rounded mt-2">
                            {info.signUpInfo.error}
                        </em>
                    ) : null}
                </form>
                <div className="border border-slate-300 lg:w-5/12"></div>
            </div>
        </div>
    );
};

export default Signup;
