import React from 'react'
import Header from '../components/Header'
import Signup from '../components/Signup'
import Footer from '../components/Footer'

const SignupPage = (props) => {
    const { signUp, setSignUp, info, setInfo, loginForm, setLoginForm } = props
    return (
        <>
            <Header />
            <Signup
                loginForm={loginForm}
                setLoginForm={setLoginForm}
                signUp={signUp}
                setSignUp={setSignUp}
                info={info}
                setInfo={setInfo}
            />
            <Footer />
        </>
    )
}

export default SignupPage