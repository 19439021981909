import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import { Link } from 'react-router-dom'


const NewTicket = (props) => {
    const cookies = new Cookies()
    const navigate = new useNavigate()
    const { newTicketForm, setNewTicketForm, info, setInfo } = props
    const { requester, subject, location, request } = newTicketForm
    const [loginState, setLoginState] = useState(true)
    const handleChange = (event) => {
        const { name, value } = event.target
        setNewTicketForm({ ...newTicketForm, [name]: value })
    }
    const handleSubmit = async (event) => {

        event.preventDefault()
        setLoginState(false)
        //const url = "http://localhost:5000/new_ticket"
        const url = "https://it-support-ticket.onrender.com/new_ticket"
        try {
            await fetch(url, {
                method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(newTicketForm)
            })
                .then(response => response.json())
                .then(data => {
                    setInfo({ ...info, ticketInfo: data })
                    setLoginState(true)
                    if (data.message) {
                        window.alert(data.message)
                        navigate("/login")
                    }

                })
        } catch (err) {
            setInfo({ ...info, ticketInfo: { error: "Sorry, try again later" } })
            setLoginState(false)
        }
    }
    const username = cookies.get("email") || ""
    return (

        <div className='flex  justify-center gap-4 w-full px-2 py-2 relative '>

            <div className='grow-2 flex w-full justify-center'>
                <form className='lg:w-6/12 w-full flex flex-col justify-center px-4 border border-slate-300 rounded-md shadow lg:p-12 p-4 bg-white' onSubmit={handleSubmit}>
                    <div className='flex lg:flex-row flex-col items-center gap-2  w-full my-2'>
                        <label className='text-sm lg:mr-8 lg:w-2/12 w-full font-normal' htmlFor='requester'>Email</label>
                        <input type='email' name='requester' id='requester' className='inline w-full border rounded outline-0 focus:outline-0 active:outline-0 px-2 py-1 lg:my-2 my-0 bg-white border-slate-300' onChange={handleChange} value={username ? username.email : requester} required />
                    </div>
                    <div className='flex lg:flex-row flex-col items-center gap-2  w-full my-2'>
                        <label className='text-sm lg:mr-8 lg:w-2/12 w-full font-normal' htmlFor='subject'>Subject</label>
                        <input type='text' name='subject' id='subject' className='inline w-full border rounded outline-0 focus:outline-0 active:outline-0 px-2 py-1 lg:my-2 my-0 bg-white border-slate-300' onChange={handleChange} value={subject} required />
                    </div>
                    <div className='flex lg:flex-row flex-col items-center gap-2  w-full my-2'>
                        <label className='text-sm lg:mr-8 lg:w-2/12 w-full font-normal' htmlFor='location'>Location</label>
                        <select type='text' name='location' id='location' className='inline w-full border rounded outline-0 focus:outline-0 active:outline-0 px-2 py-1 lg:my-2 my-0 bg-white border-slate-300' onChange={handleChange} value={location} required>
                            <option>Matori 1</option>
                            <option>Matori 2</option>
                            <option>Kopek</option>
                            <option>Ilupeju</option>
                            <option>Ikeja</option>
                            <option>PortHarcourt</option>
                            <option>Abuja</option>
                            <option>Kano</option>
                        </select>
                    </div>
                    <div className='flex lg:flex-row flex-col items-start gap-2  w-full my-2'>
                        <label className='text-sm lg:mr-8 lg:w-2/12 w-full font-normal' htmlFor='request'>Request</label>
                        <textarea rows={9} type='text' name='request' id='request' className='resize-none inline text-sm w-full border rounded outline-0 focus:outline-0 active:outline-0 px-2 py-1 lg:my-2 my-0 bg-white border-slate-300' onChange={handleChange} value={request} required></textarea>
                    </div>

                    <button type="submit" className="button_1" >

                        {
                            loginState ? "Submit" : <span className='w-full flex items-center justify-center '>
                                <div className='loading'></div>
                            </span>
                        }
                    </button>
                    <Link to={"/ticket"} className='bg-red-200 text-center my-2 rounded py-1 left-4 top-5'>Go to tickets</Link>
                    {
                        (info.ticketInfo.message) ? <em className='text-center px-2 py-1 bg-green-100 rounded mt-2'>{info.ticketInfo.message}</em> : null

                    }{
                        (info.ticketInfo.error) ? <em className='text-center px-2 py-1 bg-red-100 rounded mt-2'>{info.ticketInfo.error}</em> : null
                    }
                </form>

            </div>



        </div>


    )
}

export default NewTicket