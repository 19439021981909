import React, { useEffect } from 'react'
import Header from '../components/Header'
import Tickets from '../components/Tickets'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'


const TicketPage = (props) => {
    const cookies = new Cookies()
    const navigate = useNavigate()
    const { userInfo, setUserInfo, ticketList, setTicketList } = props
    const isAgent = cookies.get("isAgent") && cookies.get("isAgent").isAgent
    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        }
        else if (isAgent) {
            navigate("/agentDashboard")
        }
        // eslint-disable-next-line
    }, [])



    return (
        <>
            <Header userInfo={userInfo} setUserInfo={setUserInfo} />
            <Tickets ticketList={ticketList}
                setTicketList={setTicketList} />
        </>
    )

}

export default TicketPage