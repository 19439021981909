import React from "react";

const AgentDashboard = () => {
    // Test
    return (
        <div className="flex w-full agentMain min-h-screen relative">
            <section className="text-4xl p-2 gap-8 gap-2 pt-16 px-4 text-white flex flex-col items-center justify-between agent_header lg:relative absolute h-screen stay-open ">
                <div className="flex flex-col gap-8">
                    <i className="bx bxs-dashboard opacity-70 hover:opacity-100 transition ease-in-out delay-150"></i>
                    <i className="bx bx-hive opacity-70 hover:opacity-100 transition ease-in-out delay-150"></i>
                    <i className="bx bxs-user opacity-70 hover:opacity-100 transition ease-in-out delay-150"></i>
                </div>
                <i class="bx bx-log-out opacity-70 hover:opacity-100 transition ease-in-out delay-150"></i>
            </section>
            <section className="w-full ">Dashboard</section>
        </div>
    );
};

export default AgentDashboard;
