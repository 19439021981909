import React, { useEffect } from 'react'
import Header from '../components/Header'
import NewTicket from '../components/NewTicket'
import { useNavigate } from 'react-router-dom'


const NewTicketPage = (props) => {
    const navigate = useNavigate()
    const { newTicketForm, setNewTicketForm, info, setInfo, userInfo, setUserInfo } = props

    useEffect(() => {
        if (!userInfo) {
            navigate("/login")
        }
        // eslint-disable-next-line
    }, [])
    return (
        <>
            <Header userInfo={userInfo} setUserInfo={setUserInfo} />
            <NewTicket
                info={info}
                setInfo={setInfo}
                userInfo={userInfo}
                newTicketForm={newTicketForm}
                setNewTicketForm={setNewTicketForm}
            />
        </>
    )
}

export default NewTicketPage