import React from 'react'
import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'

const MainPage = (props) => {
  const { userInfo, setUserInfo } = props
  return (
    <>
      <Header userInfo={userInfo} setUserInfo={setUserInfo} />
      <Main userInfo={userInfo} />
      <Footer />
    </>
  )
}

export default MainPage