/* eslint-disable */
import React, { useEffect, useState } from 'react'
import Cookies from 'universal-cookie'
import List from './List'


const Tickets = (props) => {
  const { ticketList, setTicketList } = props
  const cookies = new Cookies()
  const email = cookies.get("email") && cookies.get("email").email
  const token = cookies.get("token") && cookies.get("token").token
  // eslint-disable-next-line

  const [myList, setMyList] = useState([])
  const [loginState, setLoginState] = useState(true)
  const [search, setSearch] = useState("")
  useEffect(() => {

    const url = "https://it-support-ticket.onrender.com/tickets?username=" + email
    // const url = "http://localhost:5000/tickets?username=" + email

    const fetchData = async () => {
      try {
        const response = await fetch(url, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,

          },
        })
        //check if response is ok
        if (response.ok) {
          const data = await response.json()
          setTicketList(data)
          setMyList(data)
          setLoginState(false)
        } else {
          alert("You are not authorised")
        }

      } catch (err) {
        console.log(err.message)
      }
    }

    fetchData()
  }, [email])

  const handleSearch = (e) => {
    setSearch(e.target.value)
    const list = ticketList.filter(item => (item.subject.toLowerCase().includes(search.toLowerCase())))
    if (list) {
      setMyList(list)
    } else {
      setMyList(ticketList)
    }



  }


  return (
    <div className='w-full flex flex-col px-2 mt-2 justify-center items-center relative '>
      <div className='lg:w-8/12 w-full lg:px-0 px-2  flex justify-between'>
        <div className='w-full'>
          <p className='text-2xl lg:my-4 my-2'>How can we help you today?</p>
          <form className='lg:w-8/12 w-full my-4 lg:pb-4 pb-2'>
            <input type="text" className="block lg:w-10/12 w-full border rounded outline-0 focus:outline-0 active:outline-0 px-2 py-1 my-2 border-slate-400" placeholder="Search by name" value={search} onChange={handleSearch} />
          </form>

          <a href='/new_ticket' className=' lg:w-3/12 w-7/12 flex items-center gap-2 text-slate-900 hover:text-slate-500'>
            <i className='bx bxs-plus-square text-2xl inline'></i>
            New Support Ticket
          </a>

        </div>
      </div>
      <div className='lg:w-8/12 w-full flex justify-between py-4'>
        <div className=' py-4 w-full px-1'>
          <h1 className='font-bold mb-4'>Your tickets</h1>
          <div className='w-full flex items-center justify-center flex-col'>
            {
              loginState ? <span className='w-full py-8 block flex items-center justify-center flex-col'>
                <div className='loading-ticket bg-slate-950'></div>
                <p className='text-slate-950 font-bold'>loading...</p>
              </span> : ticketList.length < 1 ? <p className='text-sm pt-8'>You have not raised any ticket</p> : <div className='w-full flex flex-col items-center justify-center overflow-x-scroll px-2'>
                <table className='lg:table-fixed table-auto table px-1 '>
                  <thead>
                    <tr className=' th-bg rounded-5px border-b-2 border-slate-200 py-2 h-10'>
                      <th className='text-sm pl-4'>Status</th>
                      <th className='text-sm px-2 text-start'>Request</th>
                      <th className='text-sm px-2'>Hash</th>
                      <th className='text-sm px-2'>Priority</th>
                      <th className='text-sm px-2 lg:inline hidden'>Date created</th>
                    </tr>
                  </thead>
                  <tbody className=''>
                    {
                      myList.map(item => {
                        return <List item={item} key={item._id} />
                      })
                    }
                  </tbody>
                </table>
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tickets