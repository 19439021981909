import React, { useEffect } from 'react'
import Header from '../components/Header'
import LoginBody from '../components/LoginBody'
import { useNavigate } from 'react-router-dom'


const LoginPage = (props) => {
    const navigate = useNavigate()
    const { loginForm, setLoginForm, info, setInfo, setUserInfo, setLoginToken, userInfo, loginToken } = props

    useEffect(() => {
        if (userInfo) {
            navigate("/ticket")
        }
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <Header userInfo={userInfo}
                info={info}
                setInfo={setInfo}
                setUserInfo={setUserInfo}
            />
            <LoginBody
                info={info}
                setInfo={setInfo}
                userInfo={userInfo}
                loginToken={loginToken}
                setLoginToken={setLoginToken}
                loginForm={loginForm}
                setUserInfo={setUserInfo}
                setLoginForm={setLoginForm}
            />

        </>
    )
}

export default LoginPage