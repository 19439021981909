import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';

const SingleTicket = (props) => {




    const cookies = new Cookies()
    const email = cookies.get("email").email
    const token = cookies.get("token").token
    const username = cookies.get("username").username
    const query = new URLSearchParams(useLocation().search);
    const [loginState, setLoginState] = useState(true)
    const [date, setDate] = useState("")
    const [item, setItem] = useState("")

    useEffect(() => {
        const url = "https://it-support-ticket.onrender.com/tickets?username=" + email
        // const url = "http://localhost:5000/tickets?username=" + email


        const fetchData = async () => {
            //try make a fetch request to api
            try {
                const response = await fetch(url, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,

                    },
                })
                //check if response is ok
                if (response.ok) {
                    const data = await response.json()
                    //filter the single item needed
                    const filteredItem = data.filter(item => item._id === query.get("identifier"))[0]
                    //setState of the item
                    setItem(filteredItem)

                    //calculate date elapse for the ticket
                    const currentDate = new Date().getDate();
                    const itemDate = new Date(filteredItem.date).getDate();
                    const dateLap = Number(currentDate) - Number(itemDate);

                    setDate(dateLap)

                    //cancel loading state
                    setLoginState(false)
                } else {
                    alert("You are not authorised")
                }

            } catch (err) {
                console.log(err.message)
            }
        }


        fetchData()
        // eslint-disable-next-line
    }, [false])


    return (
        <div className='w-full flex justify-center items-center relative '>
            <Link to={"/ticket"} className='absolute left-4 top-4 flex justify-center items-center h-8 w-8 font-bold rounded-full bg-slate-900 text-white'><i className='bx bx-arrow-back'></i></Link>
            {
                loginState ? <span className='w-full py-8 block flex items-center justify-center flex-col'>
                    <div className='loading-ticket bg-slate-950 my-4'></div>
                    <p className='text-slate-950 font-bold'>loading...</p>
                </span> : item.length === 0 ? <p className='text-sm pt-8'>Error: We could not find the item</p> : <div className='lg:w-9/12 w-11/12  flex lg:flex-row flex-col justify-between mt-16 gap-1'>
                    <div className='flex flex-col lg:p-4 p-2 border rounded lg:w-9/12 w-full bg-white  '>
                        <h1 className='font-bold text-2xl mb-4'>{item.subject}</h1>
                        <div className='flex items-center gap-2'>
                            <span className='w-12 h-12 p-4 rounded-full bg-red-100 border flex justify-center items-center font-bold'>{username[0]}</span>
                            <p className='font-bold text-sm'> {username},</p>
                            <p className='text-sm'>created {date === 0 ? "today." : date === 1 ? "yesterday." : date > 1 ? `${date} days ago.` : null}</p>


                        </div>
                        <textarea style={{ height: "12vh", resize: "none" }} className='text-sm px-2 py-2 mt-4 bg-grey-100 min-h-20 text-justify' disabled value={item.request} ></textarea>
                    </div>
                    <div className='flex flex-col justify-center lg:p-4 p-2 border rounded lg:w-4/12 w-full bg-white'>
                        <h1 className='font-bold mb-4 text-xl'>Agent Working on This Ticket</h1>
                        <p className='text-sm border-b pb-2 opacity-70'>{item.agent ? item.agent : "No agent assigned yet"}</p>
                        <h1 className='font-bold my-4 py-2 text-xl'>Ticket details</h1>
                        <h1 className='font-bold'>Status:</h1>
                        <p className='text-sm pb-1 opacity-70'>{item.status}</p>
                        <h1 className='font-bold mt-2'>Priority:</h1>
                        {/*eslint-disable-next-line*/}
                        <p className={`w-3/12 text-center font-bold text-sm py-1 opacity-70 rounded ${item.priority == "Low" ? "text-green-400" : item.priority == "Moderate" ? "text-orange-400" : item.priority == "High" ? "text-red-400" : null} `}>{item.priority}</p>


                    </div>
                </div>
            }



        </div>
    )
}

export default SingleTicket