import { useState } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import NewTicketPage from "./pages/NewTicketPage";
import SignupPage from "./pages/SignupPage";
import Ticket from "./pages/TicketPage";
import Cookies from "universal-cookie";
import MainPage from "./pages/MainPage";
import SingleTicketPage from "./pages/SingleTicketPage";
import AgentDashboardPage from "./pages/AgentDashboardPage";

// import "./static/css/styles.css";
import "./static/css/index.css";
import "./static/css/styles.css";
//require('dotenv').config()

const cookie = new Cookies();

function App() {
    const [info, setInfo] = useState({
        signUpInfo: "",
        loginInfo: "",
        ticketInfo: "",
    });
    const [userInfo, setUserInfo] = useState(
        cookie.get("username") ? cookie.get("username").username : ""
    );
    const [loginToken, setLoginToken] = useState("");

    const [loginForm, setLoginForm] = useState({
        username: "",
        password: "",
        remember: false,
    });

    const [signUp, setSignUp] = useState({
        username: "",
        fullName: "",
        password: "",
        confirm_password: "",
    });

    const [newTicketForm, setNewTicketForm] = useState({
        requester: cookie.get("email") ? cookie.get("email").email : "",
        subject: "",
        location: "Matori 1",
        request: "",
    });
    const [ticketList, setTicketList] = useState([]);

    //This is for admin

    return (
        <div className="App flex flex-col w-full">
            <BrowserRouter>
                <div className="main_container flex flex-col w-full">
                    <Routes>
                        <Route
                            exact
                            path="/"
                            element={
                                <MainPage
                                    userInfo={userInfo}
                                    info={info}
                                    setInfo={setInfo}
                                    setUserInfo={setUserInfo}
                                />
                            }
                        />
                        <Route
                            path="/login"
                            element={
                                <LoginPage
                                    info={info}
                                    setInfo={setInfo}
                                    userInfo={userInfo}
                                    loginToken={loginToken}
                                    setLoginToken={setLoginToken}
                                    loginForm={loginForm}
                                    setUserInfo={setUserInfo}
                                    setLoginForm={setLoginForm}
                                />
                            }
                        />

                        <Route
                            path="/new_ticket"
                            element={
                                <NewTicketPage
                                    info={info}
                                    setInfo={setInfo}
                                    setUserInfo={setUserInfo}
                                    userInfo={userInfo}
                                    newTicketForm={newTicketForm}
                                    setNewTicketForm={setNewTicketForm}
                                />
                            }
                        />
                        <Route
                            path="/ticket"
                            element={
                                <Ticket
                                    info={info}
                                    setInfo={setInfo}
                                    setUserInfo={setUserInfo}
                                    userInfo={userInfo}
                                    ticketList={ticketList}
                                    setTicketList={setTicketList}
                                    newTicketForm={newTicketForm}
                                    setNewTicketForm={setNewTicketForm}
                                />
                            }
                        />
                        <Route
                            path="/agentDashboard"
                            element={
                                <AgentDashboardPage
                                    info={info}
                                    setInfo={setInfo}
                                    setUserInfo={setUserInfo}
                                    userInfo={userInfo}
                                    ticketList={ticketList}
                                    setTicketList={setTicketList}
                                    newTicketForm={newTicketForm}
                                    setNewTicketForm={setNewTicketForm}
                                />
                            }
                        />
                        <Route
                            path="/singleticket"
                            element={
                                <SingleTicketPage
                                    info={info}
                                    setInfo={setInfo}
                                    setUserInfo={setUserInfo}
                                    userInfo={userInfo}
                                    ticketList={ticketList}
                                    setTicketList={setTicketList}
                                />
                            }
                        />
                        <Route
                            path="/signup"
                            element={
                                <SignupPage
                                    loginForm={loginForm}
                                    setLoginForm={setLoginForm}
                                    signUp={signUp}
                                    setSignUp={setSignUp}
                                    info={info}
                                    setInfo={setInfo}
                                    setUserInfo={setUserInfo}
                                />
                            }
                        />
                    </Routes>
                </div>
            </BrowserRouter>
        </div>
    );
}

export default App;
