import React from 'react'
import { Link } from 'react-router-dom'


const List = (props) => {
    //     date
    // : 
    // "2023-10-12T11:18:57.053Z"
    // location
    // : 
    // "Matori 1"
    // priority
    // : 
    // "Low"
    // request
    // : 
    // "sjkadnhkjasd"
    // requester
    // : 
    // "6527d74a9f028fc6adb3bc67"
    // status
    // : 
    // "Opened"
    // subject
    // : 
    // "New Charger"
    // __v
    // : 
    // 0
    // _id
    // : 
    // "6527e0a19f028f


    const {
        status, subject, date, _id, priority
    } = props.item

    // const query = {
    //     id: _id
    // }

    const currentDate = new Date().getDate()
    const itemDate = new Date(date).getDate()
    const calculatedDate = currentDate - itemDate

    return (


        <tr className=' tr-bg rounded-5px border-b-2 border-slate-200 py-2 h-10'>
            <td className={`text-sm pl-4 text-center ${status === "Open" || status === "New" ? "text-red-600" : status === "Being processed" ? "text-yellow-600" : status === "Closed" ? "text-green-600" : null}`}>{status}</td>
            <td className="text-sm px-2 font-bold"><Link to={`/singleticket?identifier=${_id}`}>{subject.length > 30 ? `${subject.slice(0, 30)}...` : subject}</Link></td>
            <td className="text-sm px-2 text-center">#{String(_id).slice(6, 11)}</td>
            <td className={`text-sm px-2 text-center ${priority === "Low" ? "text-green-500" : priority === "Moderate" ? "text-yellow-500" : priority === "High" ? "text-red-500" : null}`}>{priority}</td>
            <td className="text-sm px-2 lg:inline hidden">created {calculatedDate === 0 ? "yesterday." : calculatedDate === 0 ? "today." : `${calculatedDate} days ago.`}</td>
        </tr >


    )
}

export default List