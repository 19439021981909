import logo from "../static/images/logo.webp";
import passport from "../static/images/passport.jpg";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Header = (props) => {
    const navigate = useNavigate();
    const cookies = new Cookies();
    const [loginState, setLoginState] = useState(true);
    const email = cookies.get("email") ? cookies.get("email").email : "";
    const [showUserInfo, setShowUserInfo] = useState(false);

    const { userInfo, setUserInfo } = props;

    const url = "https://it-support-ticket.onrender.com";
    //const url = "http://localhost:5000"
    const handleLogout = async () => {
        setLoginState(false);
        try {
            await fetch(url + "/logout", {
                method: "POST",
            })
                .then((res) => res.json())
                .then((data) => {
                    if (data) {
                        cookies.remove("username");
                        cookies.remove("token");
                        cookies.remove("email");
                        cookies.remove("isAgent");
                        setUserInfo("");
                        navigate("/login");
                        window.alert("User logged out");
                        setLoginState(true);
                    }
                });
        } catch (err) {
            console.log(err.messages);
        }
    };

    const showBarge = () => {
        setShowUserInfo((prev) => !prev);
    };

    return (
        <header className="w-full flex justify-center item-center flex-col">
            <nav className="flex justify-center items-center w-full lg:border-b-2 border-0 relative">
                <div className="flex justify-between items-center lg:items-center w-11/12 ">
                    <div>
                        <a
                            href="/"
                            className="flex justify-center items-center"
                        >
                            <img
                                src={logo}
                                alt="logo"
                                width={100}
                                height={60}
                                className="logoImg"
                            />
                            <span className="text-xl opacity-80 lg:inline hidden">
                                {" "}
                                Federal College of Animal health and Production
                                Technology
                            </span>
                        </a>
                    </div>
                    <div className="">
                        {userInfo ? (
                            <div className="userBarge flex h-full justify-center flex-col items-center">
                                <div
                                    className="w-12 h-12 rounded-full border-4 flex items-center justify-center text-xl font-bold cursor-pointer text-white"
                                    onClick={showBarge}
                                >
                                    {userInfo[0]}
                                </div>
                                {showUserInfo ? (
                                    <div className="userInfoCard  lg:w-3/12 px-2 py-1 rounded border py-1 lg:m-0 m-auto right-5">
                                        <div className="flex gap-2 items-center px-2 ">
                                            <img
                                                src={passport}
                                                alt="userImg"
                                                width={80}
                                                className="rounded-full border"
                                            />
                                            <p className="font-bold text-center pt-1">
                                                {userInfo}
                                            </p>
                                        </div>
                                        <p className="text-sm py-1">
                                            <span className="font-bold mr-2">
                                                Email:
                                            </span>
                                            {email}
                                        </p>
                                        <p
                                            className=" text-center py-2 button_1"
                                            onClick={handleLogout}
                                        >
                                            {loginState ? (
                                                "Logout"
                                            ) : (
                                                <span className="w-full flex items-center justify-center ">
                                                    <div className="loading"></div>
                                                </span>
                                            )}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        ) : null}
                        {!userInfo ? (
                            <a href="/login" className="text-3xl">
                                <i className="bx bx-log-in-circle"></i>
                            </a>
                        ) : null}
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
