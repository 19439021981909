import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Cookies from 'universal-cookie'
import AgentDashboard from '../components/AgentDashboard'

const AgentDashboardPage = (props) => {

    const cookies = new Cookies()
    const isAgent = cookies.get("isAgent") && cookies.get("isAgent").isAgent
    const navigate = useNavigate()
    const { userInfo } = props

    useEffect(() => {
        if (!isAgent && userInfo) {
            navigate("/ticket")
        }
        // eslint-disable-next-line
    }, [])



    return (

        <>
            <AgentDashboard
                userInfo={userInfo}
            />
        </>
    )
}

export default AgentDashboardPage