import React, { useEffect } from "react";
import logo from "../static/images/logo.webp";
import { useNavigate } from "react-router-dom";

const Main = (props) => {
    const navigate = useNavigate();

    const { userInfo } = props;
    useEffect(() => {
        if (userInfo) {
            navigate("/ticket");
        }
        // eslint-disable-next-line
    }, []);

    return (
        <div className="w-full flex flex-col items-center justify-center">
            <div className="min-h-[]">
                <img src={logo} alt="Logo" width={200} />
            </div>

            <div className="flex lg:flex-row flex-col lg:w-5/12 w-11/12 px-8 justify-center lg:gap-4">
                <a href="/signup">
                    <p className="text-sm font-bold px-8 rounded border button_2 lg:inline-block ml-2 block text-center">
                        Sign up
                    </p>
                </a>
                <a href="/login">
                    <p className="text-sm font-bold px-8 rounded border button_1 lg:inline-block block ml-2 text-center">
                        Login
                    </p>
                </a>
            </div>
        </div>
    );
};

export default Main;
