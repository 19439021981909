import React from "react";

// SERVER = "https://pleasant-jade-sweatpants.cyclic.app"
// URL = "https://pleasant-jade-sweatpants.cyclic.app"

const Footer = () => {
    return (
        <footer className="w-full py-1 flex flex-col justify-center text-sm bg-gray-300 text-center">
            <p className="">
                © {new Date().getFullYear()} FCAHPT, All right reserved
            </p>
            <p className="invisible">
                Made with ♥ from Nigeria by Tobiloba Lawal
            </p>
        </footer>
    );
};

export default Footer;
